import React from "react";

export const PaginationButtons = ({ total, currentPage, onPageChange, itemsPerPage }) => {
  const pageCount = Math.ceil(total / itemsPerPage);
  if (pageCount <= 1) return null;

  return (
    <div className="flex justify-center gap-2 mt-4">
      {Array.from({ length: pageCount }).map((_, idx) => (
        <button
          key={idx}
          onClick={() => onPageChange(idx + 1)}
          className={`px-3 py-1 rounded text-sm ${
            currentPage === idx + 1
              ? "bg-blue-500 dark:bg-blue-600 text-white"
              : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600"
          }`}
        >
          {idx + 1}
        </button>
      ))}
    </div>
  );
};
