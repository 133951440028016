import { useState, useEffect } from "react";

export const useTimeAgo = (date) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      const seconds = Math.floor((new Date() - new Date(date)) / 1000);

      const intervals = {
        yr: 31536000,
        mo: 2592000,
        w: 604800,
        d: 86400,
        h: 3600,
        m: 60,
      };

      for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const interval = Math.floor(seconds / secondsInUnit);
        if (interval >= 1) {
          return `${interval}${unit} ago`;
        }
      }

      return "just now";
    };

    const updateTimeAgo = () => {
      setTimeAgo(calculateTimeAgo());
    };

    updateTimeAgo();

    // Update more frequently for recent items
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    const updateInterval =
      seconds < 300
        ? 30000 // 30s for < 5min
        : seconds < 3600
          ? 60000 // 1min for < 1hr
          : 300000; // 5min for older

    const intervalId = setInterval(updateTimeAgo, updateInterval);
    return () => clearInterval(intervalId);
  }, [date]);

  return timeAgo;
};
