import React from "react";
import { Tooltip } from "./Tooltip";
import { useTimeAgo } from "../hooks/useTimeAgo";

export const DateTime = ({ date }) => {
  const timeAgo = useTimeAgo(date);
  const fullDate = new Date(date).toUTCString();

  return (
    <Tooltip content={fullDate}>
      <span className="whitespace-nowrap">{timeAgo}</span>
    </Tooltip>
  );
};
