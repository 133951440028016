import React from "react";

export const Avatar = ({ src, alt = "User avatar", size = "md", className = "" }) => {
  const sizeClasses = {
    sm: "w-4 h-4",
    md: "w-6 h-6",
    lg: "w-8 h-8",
  };

  return (
    <img
      src={src}
      alt={alt}
      className={`rounded-full flex-shrink-0 ${sizeClasses[size]} ${className}`}
      loading="lazy"
      referrerPolicy="no-referrer"
    />
  );
};
