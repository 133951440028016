import React from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const CollapsibleSection = ({
  storageKey,
  title,
  icon: Icon,
  iconColor,
  children,
  headerActions,
  defaultCollapsed = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(storageKey, defaultCollapsed);

  return (
    <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 mb-6">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="text-base font-semibold flex items-center gap-2 dark:text-white hover:text-gray-600 dark:hover:text-gray-300 cursor-pointer"
        >
          {isCollapsed ? <ChevronRight className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
          {Icon && <Icon className={`w-4 h-4 ${iconColor}`} />}
          {title}
        </button>

        {!isCollapsed && headerActions}
      </div>

      {!isCollapsed && children}
    </div>
  );
};

export default CollapsibleSection;
