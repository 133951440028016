import React from "react";
import { formatProjectName } from "../utils";
import { CheckSquare, Bot, Ban, Scale, Eraser, GitPullRequestDraft } from "lucide-react";
import { Tooltip } from "./Tooltip";
import { DateTime } from "./DateTime";
import { PipelineStatus } from "./PipelineStatus";
import { ApprovalStatus } from "./ApprovalStatus";
import { useAuth } from "../AuthContext";
import { Avatar } from "./Avatar";

export const MergeRequest = ({ mr }) => {
  const { user } = useAuth();
  if (!mr) return null;

  const stateStyles = {
    opened: "",
    merged: "border-l-4 border-l-emerald-600 dark:border-l-emerald-500",
    closed: "border-l-4 border-l-red-600 dark:border-l-red-500",
  };

  const author = mr.author || {};
  const reviewers = (mr.reviewers?.nodes || []).filter((r) => r.approved);
  const currentUserApproved =
    mr.state === "opened" && reviewers.map((r) => r.username).includes(user?.profile?.username);
  const silent = mr.draft || currentUserApproved;

  // Unified icon style class
  const iconClass = "w-4 h-4";

  let mrSizeIcon = <></>;
  const changedLines = mr.diffStatsSummary.additions + mr.diffStatsSummary.changes;
  if (changedLines > 5000) {
    mrSizeIcon = (
      <Tooltip content="Large pull request - over 5000 lines changed">
        <Scale className={`${iconClass} text-amber-500`} />
      </Tooltip>
    );
  } else if (mr.diffStatsSummary.deletions > 2 * changedLines) {
    mrSizeIcon = (
      <Tooltip content="Cleanup PR - primarily removing or replacing code">
        <Eraser className={`${iconClass} text-blue-500`} />
      </Tooltip>
    );
  }

  return (
    <div
      className={`
        relative
        group
        bg-white dark:bg-gray-800 rounded-lg shadow p-3 mb-3
        ${silent ? "opacity-60 hover:opacity-100 transition-opacity duration-200" : ""}
        border border-gray-200 dark:border-gray-700
        ${currentUserApproved ? "border-l-4 border-l-emerald-200 dark:border-l-emerald-900" : ""}
        transition-colors duration-200
        hover:border-gray-300 dark:hover:border-gray-600
        ${stateStyles[mr.state] || ""}
      `}
    >
      <div className="flex items-start gap-2">
        <Tooltip content={author.name || "Unknown Author"}>
          <Avatar src={author.avatarUrl} alt={author.name} size="md" />
        </Tooltip>

        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-start gap-2">
            <div className="min-w-0">
              <a
                href={mr.webUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base font-semibold text-blue-600 dark:text-blue-400 hover:underline break-words"
              >
                #{mr.iid}: {mr.title}
              </a>

              <div className="text-sm text-gray-600 dark:text-gray-400 flex items-center gap-1 flex-wrap">
                <a
                  href={mr.webUrl?.split("/-/")[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {formatProjectName(mr.projectPath)}
                </a>
                <span className="flex items-center">
                  • {author.name || "Unknown"}
                  {author.bot && (
                    <Tooltip content="Bot!">
                      <Bot className={`${iconClass} ml-1 text-gray-400`} />
                    </Tooltip>
                  )}
                </span>
                <span>
                  • Created <DateTime date={mr.createdAt} />
                </span>
                <span>
                  • Updated <DateTime date={mr.updatedAt} />
                </span>
              </div>
            </div>

            <div className="flex gap-2 items-center flex-shrink-0">
              {mrSizeIcon}

              {mr.discussions > 0 && (
                <Tooltip content="Blocking discussions">
                  <span className="inline-flex items-center text-yellow-500">
                    <CheckSquare className={iconClass} />
                    <span className="ml-1 text-xs font-medium">{mr.discussions}</span>
                  </span>
                </Tooltip>
              )}

              {mr.conflicts && (
                <Tooltip content="Conflicts exist between the source and target branches">
                  <Ban className={`${iconClass} text-red-500`} />
                </Tooltip>
              )}

              <PipelineStatus pipeline={mr.headPipeline} />

              <ApprovalStatus reviewers={reviewers} />

              {mr.draft && (
                <Tooltip content="Draft pull request">
                  <GitPullRequestDraft className={`${iconClass} text-gray-500`} />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergeRequest;
