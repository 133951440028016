import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { CallbackPage } from "./components/CallbackPage";
import { Dashboard } from "./components/Dashboard";
import { LoginPage } from "./components/LoginPage";
import { LoadingSpinner } from "./components/LoadingSpinner";

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" replace />} />
      <Route path="/login" element={!user ? <LoginPage /> : <Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
