import React, { useEffect, useRef } from "react";
import { Search } from "lucide-react";

export const SearchBar = ({ searchQuery, onSearchChange, showSearch, onShowSearch }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "f") {
        e.preventDefault();
        onShowSearch(true);
        inputRef.current?.focus();
        inputRef.current?.select();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onShowSearch]);

  useEffect(() => {
    if (showSearch) {
      inputRef.current?.focus();
    }
  }, [showSearch]);

  return (
    <div className="flex-1 relative h-10">
      {(showSearch || searchQuery) && (
        <div className="absolute inset-0 animate-in slide-in-from-top-4 duration-200">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search PRs by title, author, or project... (⌘F)"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            onBlur={() => {
              if (!searchQuery) {
                onShowSearch(false);
              }
            }}
            className="w-full h-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600
              rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
          />
          <Search className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
        </div>
      )}
    </div>
  );
};
