import React, { useState } from "react";

export const Tooltip = ({ content, children }) => {
  const [show, setShow] = useState(false);

  if (!content) {
    return children;
  }

  return (
    <div className="relative inline-flex" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {show && (
        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap z-50">
          {content}
        </div>
      )}
      {children}
    </div>
  );
};
