import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userManager } from "../auth-config";

export const CallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await userManager.signinRedirectCallback();
        navigate("/");
      } catch (error) {
        console.error("Sign-in callback error:", error);
        navigate("/login");
      }
    })();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="text-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent mx-auto"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-400">Completing login...</p>
      </div>
    </div>
  );
};
