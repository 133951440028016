import { Tooltip } from "./Tooltip";
import React from "react";
import { Avatar } from "./Avatar";

export const ApprovalStatus = ({ reviewers }) => {
  if (!reviewers || reviewers.length === 0) {
    return (
      <span className="text-xs px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 whitespace-nowrap">
        No Approvals
      </span>
    );
  }

  return (
    <div className="flex -space-x-2 bg-green-100 dark:bg-green-900/30 rounded-full px-3 py-1 ring-1 ring-green-500/50 dark:ring-green-400/50">
      {reviewers.map((reviewer) => (
        <Tooltip key={reviewer.name} content={`Approved by ${reviewer.name}`}>
          <Avatar
            src={reviewer.avatarUrl}
            alt={reviewer.name}
            size="sm"
            className="border-green-500 dark:border-green-400"
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default ApprovalStatus;
