import { useAuth } from "../AuthContext";
import { useEffect } from "react";

export const LoginPage = () => {
  const { login } = useAuth();
  useEffect(() => {
    async function run() {
      window.location.href = await login();
    }
    run();
  }, [login]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="text-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent mx-auto"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-400">Redirecting to login...</p>
      </div>
    </div>
  );
};
