export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now - date;
  const diffHours = diffMs / (1000 * 60 * 60);

  if (diffHours < 24) {
    if (diffHours < 1) {
      const mins = Math.round(diffMs / (1000 * 60));
      return `${mins} minutes ago`;
    }
    return `${Math.round(diffHours)}h ago`;
  }
  return date.toLocaleDateString();
};

export const formatProjectName = (reference) => {
  const parts = reference.split("/");
  if (parts.length <= 1) return reference;

  const projectPart = parts.join("/").split("!")[0];
  return projectPart;
};

export const getCIStatusInfo = (pipeline) => {
  if (!pipeline) return null;

  switch (pipeline.status) {
    case "success":
      return { icon: "CheckCircle2", color: "text-green-500 dark:text-green-400" };
    case "failed":
      return { icon: "XCircle", color: "text-red-500 dark:text-red-400" };
    case "pending":
    case "running":
      return { icon: "CircleDot", color: "text-blue-500 dark:text-blue-400" };
    default:
      return null;
  }
};

export const sortAssignedMRs = (mergeRequests, currentUserId) => {
  return mergeRequests.sort((a, b) => {
    if (a.draft && !b.draft) return 1;
    if (!a.draft && b.draft) return -1;

    const aApprovals = a.upvotes || 0;
    const bApprovals = b.upvotes || 0;

    const aApprovedByMe = a.reviewers?.some((r) => r.id === currentUserId) || false;
    const bApprovedByMe = b.reviewers?.some((r) => r.id === currentUserId) || false;

    if (aApprovedByMe && !bApprovedByMe) return 1;
    if (!aApprovedByMe && bApprovedByMe) return -1;

    return aApprovals - bApprovals;
  });
};
