import React, { useEffect, useState } from "react";
import { Filter } from "lucide-react";

export const FilterButton = ({ showDrafts, showBotPRs, onToggleDrafts, onToggleBotPRs }) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest("[data-dropdown]")) {
        setFilterMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" data-dropdown>
      <button
        onClick={() => setFilterMenuOpen(!filterMenuOpen)}
        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
      >
        <Filter className="w-4 h-4 text-gray-500" />
      </button>

      {filterMenuOpen && (
        <div
          className="absolute right-0 mt-2 w-56 bg-white dark:bg-gray-800 rounded-lg shadow-lg border
          border-gray-200 dark:border-gray-700 py-1 z-10"
        >
          <label className="flex items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer">
            <input
              type="checkbox"
              checked={showDrafts}
              onChange={(e) => onToggleDrafts(e.target.checked)}
              className="mr-2"
            />
            Show Draft PRs
          </label>
          <label className="flex items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer">
            <input
              type="checkbox"
              checked={showBotPRs}
              onChange={(e) => onToggleBotPRs(e.target.checked)}
              className="mr-2"
            />
            Show Bot PRs
          </label>
        </div>
      )}
    </div>
  );
};
