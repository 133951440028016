import React from "react";
import { CircleDot, CheckCircle2, XCircle, Clock } from "lucide-react";
import { Tooltip } from "./Tooltip";

export const PipelineStatus = ({ pipeline }) => {
  if (!pipeline) {
    return (
      <Tooltip content="No pipeline found">
        <CircleDot className="w-4 h-4 text-yellow-400" />
      </Tooltip>
    );
  }

  // Pipeline is currently running
  if (pipeline.active) {
    return (
      <Tooltip content="Pipeline is running">
        <div className="relative">
          <Clock className="w-4 h-4 text-blue-500" />
          <span className="absolute -bottom-1 -right-1 text-[10px] font-medium bg-blue-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            1
          </span>
        </div>
      </Tooltip>
    );
  }

  // Latest pipeline has completed
  if (!pipeline.latest) {
    return (
      <Tooltip content="Pipeline status unknown">
        <CircleDot className="w-4 h-4 text-gray-400" />
      </Tooltip>
    );
  }
  const totalJobs = pipeline.totalJobs || 0;
  const failedJobs = pipeline.failedJobs || 0;
  const passedJobs = pipeline.passedJobs || 0;
  if (pipeline.status === "success" && totalJobs === pipeline.passedJobs) {
    // All checks passed
    return (
      <Tooltip content={`All checks passed (${totalJobs} total)`}>
        <div className="relative">
          <CheckCircle2 className="w-4 h-4 text-green-500" />
          <span className="absolute -bottom-1 -right-1 text-[10px] font-medium bg-green-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            ✓
          </span>
        </div>
      </Tooltip>
    );
  } else if (failedJobs > 0) {
    // Failed checks
    return (
      <Tooltip content={`${failedJobs} of ${totalJobs} checks failed`}>
        <div className="relative">
          <XCircle className="w-4 h-4 text-red-500" />
          <span className="absolute -bottom-1 -right-1 text-[10px] font-medium bg-red-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            {failedJobs}
          </span>
        </div>
      </Tooltip>
    );
  } else {
    // Checks in progress
    const remainingJobs = totalJobs - passedJobs;
    return (
      <Tooltip content={`Running checks: ${passedJobs} of ${totalJobs} completed`}>
        <div className="relative">
          <CircleDot className="w-4 h-4 text-blue-500 animate-pulse" />
          <span className="absolute -bottom-1 -right-1 text-[10px] font-medium bg-blue-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            {remainingJobs}
          </span>
        </div>
      </Tooltip>
    );
  }
};

export default PipelineStatus;
